export default {
    menu: function() {
        (function (window, $) {

            var stato = false
            $('#drop').click(function () {

                if (stato == false) {

                    $('#drop p').eq(0).addClass('ruotaP');
                    $('#drop p').eq(1).hide();
                    $('#drop p').eq(2).addClass('ruotaN');
                    $('header').removeClass('bg-white');
                    $('header').addClass('filter-invert');
                    $('#drop p').addClass('bg-white');
                    $('.burger-menu').removeClass('tx-100');

                    stato = true

                } else {
                    $('#drop p').eq(0).removeClass('ruotaP');
                    $('#drop p').eq(1).show();
                    $('#drop p').eq(2).removeClass('ruotaN');
                    $('header').addClass('bg-white');
                    $('header').removeClass('filter-invert');
                    $('#drop p').removeClass('bg-white');
                    $('.burger-menu').addClass('tx-100');
                    stato = false

                }
            });

            $('.menu-item-86, #menu-item-653').on('click', function(){
                $('.product-menu').toggleClass('close-product');
            });

            $('.product-search, .x-close, #menu-item-123').on('click', function(){
                $('.product-menu-search').toggleClass('close-search');
            });


            $('.product-menu-category').on('mouseenter', function () {
                const target = $(this).attr('data-category');
                $('.menu-image-category').hide();
                $('#' + target).show();
            })

            $(function () {
                $('[data-toggle="popover"]').popover({
                    trigger: 'focus'
                })
            })

        })(window, jQuery);
    }
}