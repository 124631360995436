
export default {
    generalAnimations: function () {
        (function (window, $) {

            $('.close-article').on('click', function(){
                $(this).toggleClass('close-article');
            });


        })(window, jQuery);
    }
}
