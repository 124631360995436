import Swiper from 'swiper';
import lightSlider from 'lightslider';


export default {
    homeSlider: function () {
        (function (window, $) {

            var swiperLatest = new Swiper('.swiper-latest-product', {
                spaceBetween: 5,
                slidesPerView: 2.2,
                loop: true,
                grabCursor: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                }
            });


            $('#vertical').lightSlider({
                gallery:true,
                easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
                item:1,
                vertical:true,
                verticalHeight:'100%',
                vThumbWidth:80,
                thumbItem:8,
                thumbMargin:4,
                slideMargin:0,
                controls: false,
                responsive : [
                    {
                        breakpoint:1024,
                        settings: {
                            item:1,
                            slideMove:1,
                            gallery:false,
                            controls: false,
                        }
                    } ]
            });


            $('#aniimated-thumbnials').lightGallery({
                thumbnail:true,
                animateThumb: false,
                showThumbByDefault: false,
                getCaptionFromTitleOrAlt: true
            });

            $('.collapse-filter').click(function () {
                $('#sort').removeClass('show');
                $('.collapse-sort').addClass('collapsed');
            });

            $('.collapse-sort').click(function () {
                $('#filter').removeClass('show');
                $('.collapse-filter').addClass('collapsed');
            });




        })(window, jQuery);
    }
}
