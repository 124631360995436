'use strict';

import global from './_global';
import lazyLoad from './_lazy-load';
import animations from './_animations'
import sliders from './_slider'
import cart from './_cart-manager'
import menu from './_header'

document.addEventListener("DOMContentLoaded", function () {

    global.pageLoadedChecker();
    global.mobileDetect();
    global.hasCoarsePointer();
    lazyLoad.lazyLoad();
    sliders.homeSlider();
    animations.generalAnimations();
    cart.cartManager();
    menu.menu();
});
