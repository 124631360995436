
export default {
    pageLoadedChecker: function () {
        (function (window, $) {
            const html = document.querySelector('html');

            function isCoarse() {
                const match = matchMedia('(pointer:coarse)').matches;
                if (match) {
                    html.classList.add('coarse');
                }
            }

            const videoloader = $('#video-loader');

            if (videoloader.length > 0){
                html.style.overflow = 'hidden';
            }

            let everythingLoaded = setInterval(function () {
                if (/loaded|complete/.test(document.readyState)) {
                    clearInterval(everythingLoaded);

                    html.classList.add('page-loaded');

                    if (videoloader.length > 0){

                        html.classList.remove('fade-home');
                        html.style.overflow = 'auto';
                    }

                    isCoarse();
                }
            }, 10);





        })(window, jQuery);
    },
    mobileDetect: function () {
        const mobileMediqQuery = window.matchMedia("(max-width: 767px)");

        mobileMediqQuery.addListener(WidthChange);

        WidthChange(mobileMediqQuery);

        function WidthChange(mobileMediqQuery) {
            (mobileMediqQuery.matches ? window.isMobile = true : window.isMobile = false);
        }
    },
    hasCoarsePointer: function () {
        const coarsePointer = matchMedia('(pointer:coarse)').matches;

        (coarsePointer ? window.hasCoarsePointer = true : window.hasCoarsePointer = false)
    }
}
