export default {
    cartManager: function () {
        const cartMenu = document.querySelector('.cart-menu');
        const productsLink = document.querySelector('.products-menu > a');

        if (cartMenu) {

            productsLink.addEventListener('click', function (event) {
                event.preventDefault();
                let cartText = cartMenu.innerText;

                let cartTotal = cartText.substring(
                    cartText.lastIndexOf("[") + 1,
                    cartText.lastIndexOf("]")
                );

                let url = productsLink.getAttribute('href');

                window.location.href = url + '?car=' + cartTotal;
            })
        }
    }
}